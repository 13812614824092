import React from 'react';
import type { IssueProfile } from '../../types/expert';

interface ProbableCausesWidgetProps {
  profile: IssueProfile;
}

export default function ProbableCausesWidget({ profile }: ProbableCausesWidgetProps) {
  if (!profile.probable_causes.length) return null;

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="space-y-6">
        {profile.probable_causes.length > 0 && (
          <div>
            <h2 className="text-lg font-medium text-gray-900 mb-4">Probable Causes</h2>
            <ul className="space-y-3">
              {profile.probable_causes.map((cause, index) => (
                <li key={index} className="flex items-center justify-between">
                  <span className="text-gray-700">{cause.cause}</span>
                  <span className="text-sm px-2 py-1 bg-blue-50 text-blue-600 rounded-full">
                    {cause.likelihood}% likely
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}