import React from 'react';

interface ProductSelectorProps {
  selectedVendor: string;
  selectedProduct: string;
  onVendorChange: (vendor: string) => void;
  onProductChange: (product: string) => void;
}

const vendors = [
  { id: 'dell', name: 'Dell' },
  { id: 'cisco', name: 'Cisco' },
  { id: 'hp', name: 'HP' },
  { id: 'vmware', name: 'VMware' },
];

const productsByVendor: Record<string, Array<{ id: string; name: string }>> = {
  dell: [
    { id: 'server', name: 'Server' },
    { id: 'laptop', name: 'Laptop' },
  ],
  cisco: [
    { id: 'firewall', name: 'Firewall' },
    { id: 'router', name: 'Router' },
  ],
  hp: [
    { id: 'printer', name: 'Printer' },
    { id: 'desktop', name: 'Desktop' },
  ],
  vmware: [
    { id: 'vm', name: 'Virtual Machine' },
  ],
};

export default function ProductSelector({
  selectedVendor,
  selectedProduct,
  onVendorChange,
  onProductChange,
}: ProductSelectorProps) {
  return (
    <div className="flex space-x-4">
      <select
        value={selectedVendor}
        onChange={(e) => onVendorChange(e.target.value)}
        className="flex-1 h-10 px-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      >
        <option value="">Select Vendor</option>
        {vendors.map((vendor) => (
          <option key={vendor.id} value={vendor.id}>
            {vendor.name}
          </option>
        ))}
      </select>
      
      <select
        value={selectedProduct}
        onChange={(e) => onProductChange(e.target.value)}
        className="flex-1 h-10 px-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        disabled={!selectedVendor}
      >
        <option value="">Select Product</option>
        {selectedVendor &&
          productsByVendor[selectedVendor].map((product) => (
            <option key={product.id} value={product.id}>
              {product.name}
            </option>
          ))}
      </select>
    </div>
  );
}