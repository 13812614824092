import React from 'react';
import { IssueProfile } from '../../types/expert';
import { FileDown, AlertCircle } from 'lucide-react';

interface IssueReportProps {
  profile: IssueProfile;
}

export default function IssueReport({ profile }: IssueReportProps) {
  const handleExport = () => {
    // Create CSV content
    const csvRows = [
      ['Issue Profile Report'],
      ['Generated', new Date().toLocaleString()],
      [''],
      ['Symptom', profile.symptom || ''],
      [''],
      ['Technical Analysis'],
      [profile.incident_report || ''],
      [''],
      ['Recommended Solution', `${profile.probable_solution?.confidence || 0}% confidence`],
      ...(profile.probable_solution?.steps.map(step => ['', step]) || []),
      [''],
      ['Probable Causes', 'Likelihood'],
      ...profile.probable_causes.map(cause => [cause.cause, `${cause.likelihood}%`]),
      [''],
      ['Responses'],
      ['Question', 'Answer', 'Time'],
      ...profile.context.map(note => [
        note.question,
        note.answer,
        new Date(note.timestamp).toLocaleString()
      ])
    ];

    // Convert to CSV string
    const csvContent = csvRows
      .map(row => row.map(cell => `"${cell}"`).join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `issue-report-${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium text-gray-900">Issue Profile</h2>
        <button
          onClick={handleExport}
          className="flex items-center px-3 py-2 text-sm text-blue-600 hover:text-blue-700"
        >
          <FileDown className="h-4 w-4 mr-2" />
          Export Report
        </button>
      </div>

      <div className="space-y-4">
        {profile.symptom && (
          <div>
            <div className="flex items-center space-x-2">
              <AlertCircle className="h-5 w-5 text-red-500" />
              <h3 className="text-lg font-medium text-gray-900">Symptom</h3>
            </div>
            <p className="mt-2 text-gray-700 text-lg">{profile.symptom}</p>
          </div>
        )}

        {profile.incident_report && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-3">Technical Analysis</h3>
            <pre className="mt-2 whitespace-pre-wrap text-gray-700 bg-gray-50 p-4 rounded-lg font-mono text-sm leading-relaxed max-h-[300px] overflow-y-auto">
              {profile.incident_report}
            </pre>
          </div>
        )}

        {Object.keys(profile.environment).length > 0 && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-3">Environment Details</h3>
            <dl className="mt-1 grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
              {Object.entries(profile.environment).map(([key, value]) => (
                <div key={key}>
                  <dt className="text-sm font-medium text-gray-600">{key}</dt>
                  <dd className="text-sm text-gray-900 mt-1">{value}</dd>
                </div>
              ))}
            </dl>
          </div>
        )}
        
        {profile.probable_solution?.rationale && (
          <div className="mt-4 p-4 bg-blue-50 rounded-lg">
            <h3 className="text-lg font-medium text-blue-900">Analysis Rationale</h3>
            <p className="mt-2 text-blue-700">
              {profile.probable_solution.rationale}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}