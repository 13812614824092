import React, { useState, useCallback } from 'react';
import { useSettings } from '../context/SettingsContext';
import QuestionRenderer from '../components/expert/QuestionRenderer';
import IssueReport from '../components/expert/IssueReport';
import ProbableCausesWidget from '../components/expert/ProbableCausesWidget';
import SolutionWidget from '../components/expert/SolutionWidget';
import TokenCounter from '../components/expert/TokenCounter';
import type { Question, IssueProfile, ExpertSession } from '../types/expert';
import { Bot } from 'lucide-react';
import { analyzeWithTokens } from '../utils/openai-helpers';
import { 
  analyzeContext, 
  generateContextBasedQuestions, 
  generateProbableCauses, 
  generateSolutions,
  updateIncidentReport 
} from '../utils/ai-helpers';
import { ContextManager } from '../utils/context-manager';

const initialProfile: IssueProfile = {
  symptom: '',
  steps_taken: [],
  environment: {},
  context: [],
  unknowns: [],
  probable_causes: [],
  incident_report: '',
  probable_solution: undefined
};

const initialQuestion: Question = {
  id: 'initial',
  text: 'Please describe the issue you are experiencing:',
  type: 'multiline',
  required: true,
};

export default function Expert() {
  const { settings } = useSettings();
  const [tokenCount, setTokenCount] = useState(0);
  const [session, setSession] = useState<ExpertSession>({
    profile: initialProfile,
    currentQuestion: initialQuestion,
    answeredQuestions: new Set(),
    isProcessing: false,
    showUnknowns: false
  });

  const resetToMainQuestion = () => {
    setSession(prev => ({
      ...prev,
      currentQuestion: initialQuestion
    }));
  };

  const processAnswer = useCallback(async (answer: string) => {
    setSession(prev => ({
      ...prev,
      isProcessing: true
    }));

    if (settings.aiProviders.openai.isEnabled) {
      const updatedProfile = { ...session.profile };
      const updatedAnsweredQuestions = new Set(session.answeredQuestions);
      updatedAnsweredQuestions.add(session.currentQuestion?.id || '');
      
      // Update initial question text after first submission
      if (session.currentQuestion?.id === 'initial') {
        initialQuestion.text = 'Tell us anything about the issue...';
      }
      
      // Update incident report with AI-enhanced formatting
      let incidentReport = updatedProfile.incident_report || '';
      
      if (session.currentQuestion?.id === 'initial') {
        updatedProfile.symptom = answer;
        updatedProfile.category = analyzeContext(answer);

        try {
          const [
            { result: solutionResult, tokens: solutionTokens },
            { result: reportResult, tokens: reportTokens },
            solutions,
            causes
          ] = await Promise.all([
            analyzeWithTokens(JSON.stringify(updatedProfile)),
            analyzeWithTokens(JSON.stringify({ profile: updatedProfile, newInfo: answer })),
            generateSolutions(updatedProfile),
            generateProbableCauses(updatedProfile)
          ]);

          setTokenCount(prev => prev + reportTokens);
          
          updatedProfile.probable_solution = solutions[0];
          updatedProfile.probable_causes = causes;
          updatedProfile.incident_report = reportResult;
        } catch (error) {
          console.error('Error processing initial response:', error);
          updatedProfile.incident_report = 'Error generating incident report. Please try again.';
        }

        updatedProfile.context.push({
          question: session.currentQuestion.text,
          answer,
          timestamp: new Date().toISOString()
        });
        
        // Generate dynamic questions based on the issue category
        updatedProfile.unknowns = await generateContextBasedQuestions(updatedProfile);
        
        // Generate initial probable causes
        updatedProfile.probable_causes = await generateProbableCauses(updatedProfile);
      } else if (session.currentQuestion?.id.startsWith('unknown-')) {
        updatedProfile.context.push({
          question: session.currentQuestion.text,
          answer,
          timestamp: new Date().toISOString()
        });
        
        // Format the diagnostic information more professionally
        const formattedAnswer = answer.trim();
        const previousReport = updatedProfile.incident_report || '';
        incidentReport = await updateIncidentReport(updatedProfile, formattedAnswer);
        
        // Generate follow-up questions based on the new context
        updatedProfile.unknowns = await generateContextBasedQuestions(updatedProfile);
        
        // Update probable causes based on new information
        updatedProfile.probable_causes = updateProbableCauses(
          updatedProfile.probable_causes,
          answer
        );
        
        // Generate new solution recommendations
        const updatedSolution = await generateSolutions(updatedProfile);
        updatedProfile.probable_solution = {
          ...updatedSolution,
          rationale: 'Updated analysis based on new information'
        };
      } else if (session.currentQuestion?.id === 'environment') {
        updatedProfile.environment = {
          ...updatedProfile.environment,
          [session.currentQuestion.text]: answer,
        };
        updatedProfile.context.push({
          question: session.currentQuestion.text,
          answer,
          timestamp: new Date().toISOString()
        });
      } else {
        updatedProfile.context.push({
          question: session.currentQuestion.text,
          answer,
          timestamp: new Date().toISOString()
        });
      }
      
      updatedProfile.incident_report = incidentReport;

      // Generate the next most relevant question based on current context
      const nextQuestion = generateNextQuestion(updatedProfile);

      setSession(prev => ({
        ...prev,
        profile: updatedProfile,
        answeredQuestions: updatedAnsweredQuestions,
        currentQuestion: nextQuestion,
        isProcessing: false,
      }));
    } else {
      await new Promise(resolve => setTimeout(resolve, 500));
      setSession(prev => ({
        ...prev,
        isProcessing: false,
        currentQuestion: {
          id: 'ai-disabled',
          text: 'AI assistance is currently disabled. Please enable it in settings to continue.',
          type: 'text',
        },
      }));
    }
  }, [session.currentQuestion, session.profile, settings.aiProviders.openai.isEnabled]);

  // Helper functions for response analysis
  const categorizeResponse = (response: string): string => {
    if (response.toLowerCase().includes('error')) return 'Error Report';
    if (response.toLowerCase().includes('changed')) return 'System Change';
    if (response.toLowerCase().includes('tried') || response.toLowerCase().includes('attempted')) 
      return 'Troubleshooting Action';
    return 'Information Update';
  };

  const assessImpactLevel = (response: string): string => {
    if (response.toLowerCase().includes('crash') || 
        response.toLowerCase().includes('down') || 
        response.toLowerCase().includes('failed')) return 'Critical';
    if (response.toLowerCase().includes('error') || 
        response.toLowerCase().includes('issue')) return 'High';
    if (response.toLowerCase().includes('warning')) return 'Medium';
    return 'Low';
  };

  const updateProbableCauses = (
    currentCauses: Array<{cause: string; likelihood: number}>,
    newInfo: string
  ) => {
    // Adjust likelihoods based on new information
    return currentCauses.map(cause => ({
      ...cause,
      likelihood: adjustLikelihood(cause, newInfo)
    })).sort((a, b) => b.likelihood - a.likelihood);
  };

  const adjustLikelihood = (
    cause: {cause: string; likelihood: number},
    newInfo: string
  ): number => {
    let adjustment = 0;
    
    if (newInfo.toLowerCase().includes(cause.cause.toLowerCase())) {
      adjustment += 15;
    }
    
    if (newInfo.toLowerCase().includes('error') && 
        cause.cause.toLowerCase().includes('error')) {
      adjustment += 10;
    }
    
    return Math.min(Math.max(cause.likelihood + adjustment, 0), 100);
  };

  const updateSolution = (
    currentSolution: {confidence: number; steps: string[]} | undefined,
    newInfo: string
  ) => {
    if (!currentSolution) return undefined;

    // Adjust solution based on new information
    return {
      confidence: Math.min(
        currentSolution.confidence + 
        (newInfo.toLowerCase().includes('success') ? 10 : 
         newInfo.toLowerCase().includes('failed') ? -10 : 0),
        100
      ),
      steps: currentSolution.steps
    };
  };

  const generateNextQuestion = (profile: IssueProfile): Question => {
    return {
      id: Date.now().toString(),
      text: 'Tell us more about what you\'ve observed or tried...',
      type: 'multiline'
    };
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">AI Expert Assistant</h1>
        <TokenCounter tokens={tokenCount} />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-3">
                <Bot className="h-8 w-8 text-blue-500" />
                <p className="text-sm text-gray-500">
                  I'll help you troubleshoot your issue step by step
                </p>
              </div>
              <button
                onClick={resetToMainQuestion}
                className="text-sm text-blue-600 hover:text-blue-700"
              >
                Return to Main Input
              </button>
            </div>

            {session.currentQuestion && (
              <QuestionRenderer
                question={session.currentQuestion}
                onAnswer={processAnswer}
                isProcessing={session.isProcessing}
              />
            )}
          </div>

          {session.profile.unknowns.length > 0 && (
            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">Suggested Questions</h3>
              </div>
              <div className="space-y-2">
                {session.profile.unknowns
                  .map((unknown, index) => (
                    !session.answeredQuestions.has(`unknown-${index}`) && (
                      <div key={index} className="flex items-center justify-between">
                        <span className="text-gray-700">{unknown}</span>
                        <button
                          onClick={() => {
                            setSession(prev => ({
                              ...prev,
                              currentQuestion: {
                                id: `unknown-${index}`,
                                text: unknown,
                                type: 'text',
                              },
                            }));
                          }}
                          className="px-3 py-1 text-sm bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100"
                        >
                          Answer
                        </button>
                      </div>
                    )
                ))}
              </div>
            </div>
          )}
          
          {session.profile.context.length > 0 && (
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Responses</h3>
              <div className="space-y-4 max-h-[400px] overflow-y-auto">
                {session.profile.context.map((note, index) => (
                  <div key={index} className="border-l-4 border-blue-500 pl-4 py-2">
                    <p className="text-sm font-medium text-gray-900">{note.question}</p>
                    <p className="text-sm text-gray-700 mt-1">{note.answer}</p>
                    <p className="text-xs text-gray-500 mt-1">
                      {new Date(note.timestamp).toLocaleTimeString()}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="space-y-6">
          <IssueReport profile={session.profile} />
          <SolutionWidget profile={session.profile} />
          <ProbableCausesWidget profile={session.profile} />
        </div>
      </div>
    </div>
  );
}