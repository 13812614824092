import type { IssueProfile } from '../types/expert';
import { analyzeWithTokens } from './openai-helpers';

// Impact assessment levels and descriptions
const IMPACT_LEVELS = {
  critical: {
    description: 'Critical - Immediate action required',
    keywords: ['crash', 'failure', 'down', 'broken', 'unusable']
  },
  high: {
    description: 'High - Significant impact on operations',
    keywords: ['error', 'malfunction', 'severe', 'major']
  },
  medium: {
    description: 'Medium - Limited functionality affected',
    keywords: ['degraded', 'slow', 'warning', 'partial']
  },
  low: {
    description: 'Low - Minimal impact on operations',
    keywords: ['minor', 'cosmetic', 'occasional', 'slight']
  }
};

export function analyzeContext(text: string): string {
  const categories = {
    network: ['cisco', 'network', 'firewall', 'switch', 'router', 'connectivity'],
    system: ['windows', 'linux', 'mac', 'boot', 'os', 'operating system'],
    hardware: ['dell', 'lenovo', 'hp', 'server', 'hardware', 'physical'],
    software: ['application', 'software', 'program', 'app'],
    security: ['security', 'breach', 'vulnerability', 'attack', 'threat']
  };

  const lowercaseText = text.toLowerCase();
  for (const [category, keywords] of Object.entries(categories)) {
    if (keywords.some(keyword => lowercaseText.includes(keyword))) {
      return category;
    }
  }
  
  return 'general';
}

export async function generateContextBasedQuestions(profile: IssueProfile): Promise<string[]> {
  const { result } = await analyzeWithTokens(`
    Given this context:
    Category: ${profile.category || 'Unknown'}
    Symptom: ${profile.symptom || 'Not specified'}
    Previous Answers: ${profile.context?.map(c => `Q: ${c.question} A: ${c.answer}`).join('\n') || 'None'}

    Generate 3-5 relevant technical questions to help diagnose this issue.
    Focus on gathering specific technical details and avoid questions already answered.
    Questions should be specific to the ${profile.category || 'current'} category.
  `);

  try {
    // Parse questions from AI response
    const questions = result
      .split('\n')
      .map(line => line.replace(/^\d+\.\s*/, '').trim())
      .filter(q => q.length > 0 && q.endsWith('?'));

    // Filter out already answered questions
    const answeredQuestions = new Set(profile.context.map(c => c.question));
    return questions.filter(q => !answeredQuestions.has(q));
  } catch (error) {
    console.error('Error generating questions:', error);
    return [];
  }
}

export async function generateSolutions(profile: IssueProfile) {
  const { result } = await analyzeWithTokens(`
    Given this issue profile:
    Category: ${profile.category || 'Unknown'}
    Symptom: ${profile.symptom || 'Not specified'}
    Context: ${profile.context?.map(c => `Q: ${c.question} A: ${c.answer}`).join('\n') || 'None'}

    Respond ONLY with a JSON object in this exact format, nothing else:
    {
      "solutions": [
        {"confidence": number, "steps": string[]},
        {"confidence": number, "steps": string[]},
        {"confidence": number, "steps": string[]}
      ]
    }
    
    Include three solutions with these confidence levels:
    1. High confidence (70-100%): Most likely to resolve the issue
    2. Medium confidence (40-69%): Alternative approaches
    3. Low confidence (20-39%): Last resort options
  `);

  try {
    // Clean the result string to ensure valid JSON
    const cleanResult = result.replace(/[\r\n\t]/g, '').replace(/^[^{]*({.*})[^]*$/, '$1').trim();
    const parsed = JSON.parse(cleanResult);
    
    if (!parsed || !Array.isArray(parsed.solutions)) {
      throw new Error('Invalid solution format received');
    }
    
    const validSolutions = parsed.solutions
      .filter((sol: any) => 
        typeof sol.confidence === 'number' && 
        Array.isArray(sol.steps) &&
        sol.steps.every((step: any) => typeof step === 'string')
      )
      .sort((a: any, b: any) => b.confidence - a.confidence);
    
    if (validSolutions.length === 0) {
      throw new Error('No valid solutions found in response');
    }
    
    return validSolutions.length > 0 ? validSolutions : [{
      confidence: 50,
      steps: [
        'Document current system state',
        'Review recent changes',
        'Check system logs',
        'Test in isolated environment'
      ]
    }];
  } catch (error) {
    console.error('Error generating solutions:', error);
    return [{
      confidence: 50,
      steps: [
        'Document current system state',
        'Review recent changes',
        'Check system logs',
        'Test in isolated environment'
      ]
    }];
  }
}

export async function updateIncidentReport(profile: IssueProfile, newInfo: string): Promise<string> {
  const { result } = await analyzeWithTokens(`
    Given this context:
    Category: ${profile.category}
    Category: ${profile.category || 'Unknown'}
    Symptom: ${profile.symptom || 'Not specified'}
    History: ${profile.context?.map(c => `Q: ${c.question} A: ${c.answer}`).join('\n') || 'None'}
    New Information: ${newInfo}

    Generate a professional technical analysis report that:
    1. Summarizes the issue
    2. Analyzes the symptoms
    3. Details the diagnostic steps taken
    4. Provides current status
    5. Includes technical observations

    Format as a structured report with clear sections.
  `);

  return `Technical Analysis Report
Generated: ${new Date().toLocaleString()}

${result}`;
}

export async function generateProbableCauses(profile: IssueProfile) {
  const { result } = await analyzeWithTokens(`
    Given this issue profile:
    Category: ${profile.category || 'Unknown'}
    Symptom: ${profile.symptom || 'Not specified'}
    Context: ${profile.context?.map(c => `Q: ${c.question} A: ${c.answer}`).join('\n') || 'None'}
    
    Generate a list of probable causes with likelihood percentages that sum to 100%.
    Respond ONLY with a JSON array in this exact format, nothing else:
    [{"cause": "string description", "likelihood": number}]
    
    Ensure causes are specific to the ${profile.category || 'current'} category.
  `);

  try {
    // Clean the result string to ensure valid JSON
    const cleanResult = result.replace(/[\r\n\t]/g, '').replace(/^[^[]*(\[.*\])[^]*$/, '$1').trim();
    const causes = JSON.parse(cleanResult);
    
    if (!Array.isArray(causes)) {
      throw new Error('Invalid causes format received');
    }
    
    const validCauses = causes.map((cause: any) => ({
      cause: cause.cause,
      likelihood: cause.likelihood
    })).filter(cause => 
      typeof cause.cause === 'string' && 
      typeof cause.likelihood === 'number' &&
      cause.likelihood >= 0 &&
      cause.likelihood <= 100
    );
    
    if (validCauses.length === 0) {
      throw new Error('No valid causes found in response');
    }
    
    // Validate total equals 100%
    const total = validCauses.reduce((sum: number, cause: any) => sum + cause.likelihood, 0);
    if (Math.abs(total - 100) > 1) {
      // Normalize probabilities to sum to 100%
      return validCauses.map(cause => ({
        ...cause,
        likelihood: Math.round((cause.likelihood / total) * 100)
      }));
    }
    
    return validCauses;
  } catch (error) {
    console.error('Error generating probable causes:', error);
    return [
      { cause: 'Configuration Issue', likelihood: 35 },
      { cause: 'Hardware Problem', likelihood: 25 },
      { cause: 'Software Error', likelihood: 20 },
      { cause: 'Environmental Factor', likelihood: 20 }
    ];
  }
}