import React from 'react';
import { Timer } from 'lucide-react';
import type { Ticket } from '../../../types/dashboard';
import { clsx } from 'clsx';

interface TicketsWidgetProps {
  tickets: Ticket[];
}

const statusColors = {
  open: 'bg-yellow-50 text-yellow-700',
  in_progress: 'bg-blue-50 text-blue-700',
  resolved: 'bg-green-50 text-green-700',
};

const priorityColors = {
  low: 'bg-gray-50 text-gray-600',
  medium: 'bg-orange-50 text-orange-700',
  high: 'bg-red-50 text-red-700',
};

export default function TicketsWidget({ tickets }: TicketsWidgetProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Recent Tickets</h3>
      <div className="space-y-3">
        {tickets.map((ticket) => (
          <div
            key={ticket.id}
            className="p-4 rounded-lg border border-gray-100 hover:border-gray-200 transition-colors"
          >
            <div className="flex items-start justify-between">
              <div className="flex items-start">
                <Timer className="h-5 w-5 text-gray-400 mt-0.5 mr-3" />
                <div>
                  <h4 className="font-medium text-gray-900">{ticket.title}</h4>
                  <div className="flex items-center space-x-2 mt-2">
                    <span
                      className={clsx(
                        'px-2 py-1 text-xs rounded-full',
                        statusColors[ticket.status]
                      )}
                    >
                      {ticket.status.replace('_', ' ').toUpperCase()}
                    </span>
                    <span
                      className={clsx(
                        'px-2 py-1 text-xs rounded-full',
                        priorityColors[ticket.priority]
                      )}
                    >
                      {ticket.priority.toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
              <span className="text-sm text-gray-500">
                {new Date(ticket.created).toLocaleDateString()}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}