import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Playbook from '../pages/Playbook';
import Modules from '../pages/Modules';
import FixFinder from '../pages/FixFinder';
import Dashboard from '../pages/Dashboard';
import Expert from '../pages/Expert';
import Demo from '../pages/Demo';
import Settings from '../pages/Settings';
import UserMenu from './UserMenu';

export default function Layout() {
  const [isDocked, setIsDocked] = React.useState(true);

  return (
    <div className="flex h-screen bg-gray-50">
      <div className={`transition-all duration-300 ${isDocked ? 'w-64' : 'w-0'}`}>
        <Sidebar />
      </div>
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-white border-b border-gray-200">
          <div className="px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
            <div className="flex-1">
              <input
                type="search"
                placeholder="Search devices, issues, or documentation..."
                className="w-full max-w-lg h-10 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <UserMenu />
          </div>
        </header>
        <main className="flex-1 overflow-hidden bg-gray-50">
          <div className="h-full">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/modules" element={<Modules />} />
              <Route path="/fixfinder" element={<FixFinder />} />
              <Route path="/expert" element={<Expert />} />
              <Route path="/demo" element={<Demo />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/playbook" element={<Playbook />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
}