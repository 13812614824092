import React from 'react';
import { Bot } from 'lucide-react';

interface TokenCounterProps {
  tokens: number;
}

export default function TokenCounter({ tokens }: TokenCounterProps) {
  return (
    <div className="flex items-center space-x-2 text-sm text-gray-500">
      <Bot className="h-4 w-4" />
      <span>{tokens.toLocaleString()} tokens used</span>
    </div>
  );
}