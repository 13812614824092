import type { ProductData } from '../types/dashboard';

export const mockProductData: Record<string, Record<string, ProductData>> = {
  dell: {
    server: {
      vendor: 'Dell',
      product: 'Server',
      resources: [
        { title: 'Dell Support Portal', url: 'https://www.dell.com/support' },
        { title: 'PowerEdge Documentation', url: 'https://www.dell.com/poweredge/docs' },
      ],
      issues: [
        {
          title: 'RAID Controller Failure',
          description: 'System reports RAID controller errors during POST',
          severity: 'high',
        },
        {
          title: 'System Overheating',
          description: 'Temperature sensors reporting above normal readings',
          severity: 'medium',
        },
      ],
      guides: [
        {
          title: 'RAID Configuration Guide',
          url: 'https://dell.com/raid-guide',
          type: 'pdf',
        },
        {
          title: 'Troubleshooting Video Series',
          url: 'https://dell.com/server-videos',
          type: 'video',
        },
      ],
      tickets: [
        {
          id: 'T1001',
          title: 'Server not booting after power outage',
          status: 'in_progress',
          priority: 'high',
          created: '2024-03-15T10:30:00Z',
        },
        {
          id: 'T1002',
          title: 'Regular system maintenance',
          status: 'open',
          priority: 'low',
          created: '2024-03-14T15:45:00Z',
        },
      ],
    },
  },
  cisco: {
    firewall: {
      vendor: 'Cisco',
      product: 'Firewall',
      resources: [
        { title: 'Cisco ASA Documentation', url: 'https://www.cisco.com/asa/docs' },
        { title: 'Security Advisories', url: 'https://www.cisco.com/security' },
      ],
      issues: [
        {
          title: 'VPN Connection Drops',
          description: 'Remote users experiencing intermittent VPN disconnections',
          severity: 'high',
        },
        {
          title: 'High CPU Usage',
          description: 'Firewall showing sustained high CPU utilization',
          severity: 'medium',
        },
      ],
      guides: [
        {
          title: 'VPN Configuration Guide',
          url: 'https://cisco.com/vpn-guide',
          type: 'web',
        },
        {
          title: 'Security Best Practices',
          url: 'https://cisco.com/security-guide',
          type: 'pdf',
        },
      ],
      tickets: [
        {
          id: 'T2001',
          title: 'ACL configuration review',
          status: 'open',
          priority: 'medium',
          created: '2024-03-15T09:20:00Z',
        },
        {
          id: 'T2002',
          title: 'Firmware upgrade planning',
          status: 'in_progress',
          priority: 'low',
          created: '2024-03-14T14:30:00Z',
        },
      ],
    },
  },
};