
import React from 'react';
import { useSettings } from '../context/SettingsContext';
import QuestionRenderer from '../components/expert/QuestionRenderer';
import IssueReport from '../components/expert/IssueReport';
import ProbableCausesWidget from '../components/expert/ProbableCausesWidget';
import SolutionWidget from '../components/expert/SolutionWidget';
import TokenCounter from '../components/expert/TokenCounter';
import { Bot } from 'lucide-react';

const demoProfile = {
  symptom: 'User is having a slow boot up on Windows 11',
  incident_report: 'Initial analysis indicates Service Pack corruption as the primary cause. Event logs show random Windows processes crashing and timeouts with client event handler during login. System diagnostics (chkdsk, DISM) show no immediate file system issues. Boot time measured at approximately 9 minutes.\n\nRecent changes include Windows 11 24H2 update and .NET framework update. Dell Command Update has not run recently. Hardware diagnostics via Dell F12 thorough check passed all components.',
  steps_taken: ['Checked Event Logs', 'Ran chkdsk and DISM commands', 'Used msconfig for diagnostic startup'],
  environment: {
    'OS Version': 'Windows 11 24H2',
    'Hardware': 'Dell Commercial Device',
    'Last Update': '< 1 week ago'
  },
  context: [
    {
      question: 'When did the issue start?',
      answer: 'One week ago the user noticed it.',
      timestamp: new Date().toISOString()
    },
    {
      question: 'Have you timed the boot-up?',
      answer: 'Yes, it took 9 minutes while I waited on the phone.',
      timestamp: new Date().toISOString()
    },
    {
      question: 'When was the last time updates were installed?',
      answer: 'In the RMM I found that 24H2 was installed and a .NET update.',
      timestamp: new Date().toISOString()
    }
  ],
  unknowns: [
    'Did the repair of the Service Pack reduce the boot-up time?',
    'Are there any pending driver updates in Dell Command Update?',
    'Have you checked disk performance metrics?',
    'Are there any third-party startup applications?'
  ],
  probable_causes: [
    { cause: 'Service Pack Corruption', likelihood: 70 },
    { cause: 'Hard Drive Degradation', likelihood: 15 },
    { cause: 'Other Hardware Failure', likelihood: 10 },
    { cause: 'Drive Conflicts', likelihood: 5 }
  ],
  probable_solution: {
    confidence: 85,
    steps: [
      'Repair or reimage the device',
      'Check Service Pack compatibility block list',
      'Perform fresh reinstall via Cloud download in Recovery'
    ],
    rationale: 'Based on timing correlation with recent updates and event log patterns, Service Pack corruption is the most likely cause.'
  }
};

export default function Demo() {
  const { settings } = useSettings();
  
  return (
    <div className="h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 overflow-y-auto">
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">AI Expert Assistant (Demo)</h1>
          <TokenCounter tokens={1234} />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-3">
                  <Bot className="h-8 w-8 text-blue-500" />
                  <p className="text-sm text-gray-500">
                    Demo scenario: Windows 11 slow boot issue
                  </p>
                </div>
              </div>
              <QuestionRenderer
                question={{
                  id: 'demo',
                  text: 'Please describe any additional observations...',
                  type: 'multiline'
                }}
                onAnswer={() => {}}
                isProcessing={false}
              />
            </div>
            <IssueReport profile={demoProfile} />
          </div>

          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Questions</h3>
              <div className="space-y-2">
                {demoProfile.unknowns.map((question, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <span className="text-gray-700">{question}</span>
                    <button className="px-3 py-1 text-sm bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100">
                      Answer
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <SolutionWidget profile={demoProfile} />
            <ProbableCausesWidget profile={demoProfile} />
          </div>
        </div>
      </div>
    </div>
  );
}
