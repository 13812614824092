
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './components/Layout';
import { SettingsProvider } from './context/SettingsContext';
import { Navigate, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <SettingsProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Layout />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </BrowserRouter>
    </SettingsProvider>
  );
}

export default App;
