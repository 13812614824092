import React, { useState } from 'react';
import { Key, Save } from 'lucide-react';
import { useSettings } from '../context/SettingsContext';

export default function Settings() {
  const { settings, updateAIProvider, toggleAIProvider } = useSettings();
  const [openaiKey, setOpenaiKey] = useState(settings.aiProviders.openai.key);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSave = () => {
    updateAIProvider('openai', openaiKey);
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
      </div>

      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">AI Integration</h2>
          
          <div className="space-y-4">
            <div>
              <label className="flex items-center mb-2">
                <input
                  type="checkbox"
                  checked={settings.aiProviders.openai.isEnabled}
                  onChange={() => toggleAIProvider('openai')}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-gray-700">Enable OpenAI Integration</span>
              </label>
              
              <div className="flex space-x-4">
                <div className="flex-1">
                  <div className="relative">
                    <input
                      type="password"
                      value={openaiKey}
                      onChange={(e) => setOpenaiKey(e.target.value)}
                      placeholder="Enter your OpenAI API key"
                      className="w-full h-10 pl-10 pr-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <Key className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                  </div>
                </div>
                <button
                  onClick={handleSave}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center"
                >
                  <Save className="h-5 w-5 mr-2" />
                  Save
                </button>
              </div>
            </div>

            {showSuccess && (
              <div className="bg-green-50 text-green-700 px-4 py-2 rounded-lg">
                Settings saved successfully
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}