import React, { createContext, useContext, useState } from 'react';
import type { Settings } from '../types/settings';

interface SettingsContextType {
  settings: Settings;
  updateAIProvider: (provider: 'openai', key: string) => void;
  toggleAIProvider: (provider: 'openai') => void;
}

const defaultSettings: Settings = {
  aiProviders: {
    openai: {
      name: 'OpenAI',
      key: 'sk-svcacct-S9Fux7KqC1aFCOC0ExFhUqa3xjOTJuA6yn4a3jqTpTfLXAD4eFwVKrpAf6b17iXT3BlbkFJ3HRHkrzhr8P7OH_pi-WJ0zCQbFLxvhTPafi_-0xAlsctbguXuIEIFGo651IwGAA',
      isEnabled: true,
    },
  },
};

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export function SettingsProvider({ children }: { children: React.ReactNode }) {
  const [settings, setSettings] = useState<Settings>(defaultSettings);

  const updateAIProvider = (provider: 'openai', key: string) => {
    setSettings((prev) => ({
      ...prev,
      aiProviders: {
        ...prev.aiProviders,
        [provider]: {
          ...prev.aiProviders[provider],
          key,
        },
      },
    }));
  };

  const toggleAIProvider = (provider: 'openai') => {
    setSettings((prev) => ({
      ...prev,
      aiProviders: {
        ...prev.aiProviders,
        [provider]: {
          ...prev.aiProviders[provider],
          isEnabled: !prev.aiProviders[provider].isEnabled,
        },
      },
    }));
  };

  return (
    <SettingsContext.Provider value={{ settings, updateAIProvider, toggleAIProvider }}>
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
}