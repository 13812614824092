import React from 'react';
import { AlertCircle } from 'lucide-react';
import type { Issue } from '../../../types/dashboard';
import { clsx } from 'clsx';

interface IssuesWidgetProps {
  issues: Issue[];
}

const severityColors = {
  low: 'bg-yellow-50 text-yellow-700',
  medium: 'bg-orange-50 text-orange-700',
  high: 'bg-red-50 text-red-700',
};

export default function IssuesWidget({ issues }: IssuesWidgetProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Known Issues</h3>
      <div className="space-y-3">
        {issues.map((issue, index) => (
          <div
            key={index}
            className="p-4 rounded-lg border border-gray-100 hover:border-gray-200 transition-colors"
          >
            <div className="flex items-start">
              <AlertCircle className="h-5 w-5 text-gray-400 mt-0.5 mr-3" />
              <div>
                <h4 className="font-medium text-gray-900">{issue.title}</h4>
                <p className="text-sm text-gray-600 mt-1">{issue.description}</p>
                {issue.severity && (
                  <span
                    className={clsx(
                      'inline-block px-2 py-1 text-xs rounded-full mt-2',
                      severityColors[issue.severity]
                    )}
                  >
                    {issue.severity.charAt(0).toUpperCase() + issue.severity.slice(1)}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}