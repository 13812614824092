import React from 'react';
import { Link } from 'lucide-react';
import type { Resource } from '../../../types/dashboard';

interface ResourcesWidgetProps {
  resources: Resource[];
}

export default function ResourcesWidget({ resources }: ResourcesWidgetProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Popular Resources</h3>
      <div className="space-y-3">
        {resources.map((resource, index) => (
          <a
            key={index}
            href={resource.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center p-3 rounded-lg hover:bg-gray-50 transition-colors"
          >
            <Link className="h-5 w-5 text-blue-500 mr-3" />
            <span className="text-gray-700">{resource.title}</span>
          </a>
        ))}
      </div>
    </div>
  );
}