import React, { useState } from 'react';
import { IssueProfile } from '../../types/expert';
import { clsx } from 'clsx';

interface SolutionWidgetProps {
  profile: IssueProfile;
}

type ConfidenceLevel = 'high' | 'medium' | 'low';

const confidenceRanges = {
  high: { min: 70, label: 'High Confidence', color: 'green' },
  medium: { min: 40, label: 'Medium Confidence', color: 'yellow' },
  low: { min: 0, label: 'Low Confidence', color: 'red' }
};

export default function SolutionWidget({ profile }: SolutionWidgetProps) {
  const [selectedConfidence, setSelectedConfidence] = useState<ConfidenceLevel>('high');

  const getConfidenceLevel = (confidence: number): ConfidenceLevel => {
    if (confidence >= 70) return 'high';
    if (confidence >= 40) return 'medium';
    return 'low';
  };

  const solutions = profile.probable_solution ? [
    {
      confidence: profile.probable_solution.confidence,
      steps: profile.probable_solution.steps
    },
    {
      confidence: Math.max(30, profile.probable_solution.confidence - 30),
      steps: [
        'Repair or Reimage the device if the issue persists',
        'Check block list for Service Pack compatibility issues',
        'Fresh reinstall of Service Pack via Cloud download in Recovery'
      ]
    },
    {
      confidence: Math.max(20, profile.probable_solution.confidence - 50),
      steps: [
        'Fix Problems using Windows Update in Recovery menu',
        'Use Reinstall Now button above Reset this PC',
        'Repair latest Service Pack installation'
      ]
    },
    {
      confidence: Math.max(15, profile.probable_solution.confidence - 60),
      steps: [
        'Run DISM and CHKDSK',
        'Update Hard Drive Firmware and Video Drivers',
        'Perform Defrag and Malware Cleanup'
      ]
    }
  ] : [];

  const filteredSolutions = solutions.filter(solution => {
    const level = getConfidenceLevel(solution.confidence);
    return selectedConfidence === level;
  });

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-medium text-gray-900">Recommended Solutions</h2>
        <div className="flex space-x-2">
          {(['high', 'medium', 'low'] as const).map((level) => (
            <button
              key={level}
              onClick={() => setSelectedConfidence(level)}
              className={clsx(
                'px-3 py-1 text-sm rounded-full transition-colors',
                selectedConfidence === level
                  ? `bg-${confidenceRanges[level].color}-100 text-${confidenceRanges[level].color}-700 ring-2 ring-${confidenceRanges[level].color}-500`
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              )}
            >
              {confidenceRanges[level].label}
            </button>
          ))}
        </div>
      </div>
      <div className="space-y-6">
        {!profile.probable_solution ? (
          <p className="text-gray-600">
            Not enough information yet to generate solution recommendations.
            Continue answering expert questions to help narrow down the issue.
          </p>
        ) : filteredSolutions.length === 0 && (
          <p className="text-gray-600">
            No solutions available at this confidence level. Try adjusting the confidence filter to see more options.
          </p>
        )}
        {filteredSolutions.map((solution, index) => (
          <div key={index} className="space-y-3">
            <div className="flex items-center">
              <span className={clsx(
                'px-2 py-1 text-sm rounded-full',
                solution.confidence >= 70 
                  ? 'bg-green-50 text-green-700' 
                  : solution.confidence >= 40 
                    ? 'bg-yellow-50 text-yellow-700' 
                    : 'bg-red-50 text-red-700'
              )}>
                {solution.confidence}% confidence
              </span>
            </div>
            <ol className="list-decimal list-inside space-y-2">
              {solution.steps.map((step, stepIndex) => (
                <li key={stepIndex} className="text-gray-700">{step}</li>
              ))}
            </ol>
          </div>
        ))}
      </div>
    </div>
  );
}