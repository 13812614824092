import type { IssueProfile } from '../types/expert';

interface OpenAICompletion {
  choices: Array<{
    text: string;
    finish_reason: string;
  }>;
  usage: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
}

export async function callOpenAI(prompt: string): Promise<OpenAICompletion> {
  try {
    if (!prompt?.trim()) {
      throw new Error('Empty prompt provided');
    }

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY || ''}`
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are an expert IT troubleshooting assistant. Provide technical analysis and solutions. Always respond in valid JSON format when requested."
          },
          {
            role: "user",
            content: prompt
          }
        ],
        max_tokens: 500,
        temperature: 0.3
      })
    });
  
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.error('OpenAI API error:', errorData);
      throw new Error(`OpenAI API error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    
    if (!data?.choices?.[0]?.message?.content) {
      throw new Error('Invalid response format from OpenAI');
    }

    return {
      choices: [{
        text: data.choices[0].message.content,
        finish_reason: data.choices[0].finish_reason
      }],
      usage: data.usage || { prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 }
    };
  } catch (error) {
    console.error('OpenAI API call failed:', error);
    throw error;
  }
}

export async function getTokenUsage(response: OpenAICompletion): Promise<number> {
  return response.usage.total_tokens;
}

export async function analyzeWithTokens(prompt: string): Promise<{
  result: string;
  tokens: number;
}> {
  try {
    const response = await callOpenAI(prompt);
    
    if (!response.choices || !response.choices[0]) {
      throw new Error('Invalid response from OpenAI API');
    }
    
    return {
      result: response.choices[0].text.trim(),
      tokens: response.usage.total_tokens
    };
  } catch (error) {
    console.error('Analysis failed:', error);
    return {
      result: 'Unable to analyze at this time. Please try again.',
      tokens: 0
    };
  }
}