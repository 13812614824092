import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../components/dashboard/SearchBar';
import ProductSelector from '../components/dashboard/ProductSelector';
import ResourcesWidget from '../components/dashboard/widgets/ResourcesWidget';
import IssuesWidget from '../components/dashboard/widgets/IssuesWidget';
import TicketsWidget from '../components/dashboard/widgets/TicketsWidget';
import GuidesWidget from '../components/dashboard/widgets/GuidesWidget';
import { useSettings } from '../context/SettingsContext';
import { mockProductData } from '../data/mockData';

export default function Dashboard() {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');

  const handleSearch = useCallback(async (query: string) => {
    navigate('/expert');
  }, [navigate]);

  const productData = selectedVendor && selectedProduct
    ? mockProductData[selectedVendor]?.[selectedProduct]
    : null;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
      </div>

      <div className="space-y-6">
        <SearchBar
          onSearch={handleSearch}
          isAIEnabled={settings.aiProviders.openai.isEnabled}
          onToggleAI={() => {}}
        />

        <ProductSelector
          selectedVendor={selectedVendor}
          selectedProduct={selectedProduct}
          onVendorChange={setSelectedVendor}
          onProductChange={setSelectedProduct}
        />

        {productData ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <ResourcesWidget resources={productData.resources} />
            <IssuesWidget issues={productData.issues} />
            <TicketsWidget tickets={productData.tickets} />
            <GuidesWidget guides={productData.guides} />
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-sm p-8 text-center">
            <p className="text-gray-500">
              Select a vendor and product to view the dashboard
            </p>
          </div>
        )}
      </div>
    </div>
  );
}