import React, { useState } from 'react';
import { Search, Zap } from 'lucide-react';
import { clsx } from 'clsx';

interface SearchBarProps {
  onSearch: (query: string) => void;
  isAIEnabled: boolean;
  onToggleAI: () => void;
}

export default function SearchBar({ onSearch, isAIEnabled, onToggleAI }: SearchBarProps) {
  const [query, setQuery] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (query.trim()) {
      onSearch(query);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative flex items-center">
      <div className="relative flex-1">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="How can we help you?"
          className="w-full h-12 pl-12 pr-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
      </div>
      <button
        type="button"
        onClick={onToggleAI}
        className={clsx(
          'ml-4 px-4 py-2 rounded-lg flex items-center transition-colors',
          isAIEnabled
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        )}
      >
        <Zap className="h-4 w-4 mr-2" />
        {isAIEnabled ? 'AI Enabled' : 'Simulated'}
      </button>
    </form>
  );
}